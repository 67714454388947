import React, { useContext } from "react";
import Modal from "react-modal";
import { StoreContext } from "../store/StoreContext";
import { ActionType } from "../types";
import "./Result.scss";

Modal.setAppElement("body");

const Result = () => {
  const [state, dispatch] = useContext(StoreContext);

  const descriptionText = (): string => {
    if (state.category === "слабо") {
      return "Обсъдете със специалист възможните причини за загубата на теглото ви и ако е нужно, предприемете нужните мерки.";
    } else if (state.category === "нормално") {
      return "Поддържането на здравословно тегло намалява риска от развитието на хронични заболявания. Продължавай в същият дух!";
    } else if (state.category === "наднормено") {
      return "Повишен риск за поява на хронични заболявания като диабет и високо кръвно налягане. Опитайде да водите по-здравословен начин на живот.";
    } else {
      return "Съществува риск за повишаване на кръвното ви налягане и холестерола. Нужно е да се консултирате със специалист.";
    }
  };

  return (
    <Modal
      isOpen={state.modalIsOpen}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => {
        dispatch({ type: ActionType.ToggleModal });
      }}
      className="modal"
      overlayClassName="overlay"
      bodyOpenClassName="modal-body--open"
      closeTimeoutMS={200}>
      <h1 className={`bmi-group bmi-group_${state.category}`}>
        {state.category.toUpperCase()}
      </h1>
      <p className="bmi-value-text">
        Вашият индекс на телесната маса е <strong>{state.bmi}</strong>
      </p>
      <p className="description-text">{descriptionText()}</p>
    </Modal>
  );
};

export default Result;
